import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ProjectComponent from "../component/ProjectComponent";
import { MetaTags } from "react-meta-tags";

const HoodExhaustSystem = () => {
  const content = {
    title: "Hood Exhaust System",
    description:
      "The first impression matters a lot. So it is with display counter. Such display counters are used in many restaurants as they are space-efficient and easy to manage. Now, most of us often get confused about where to buy and how to buy. Most display counters (डिस्प्ले काउंटर) lag somewhere, the customer first buys and realizes it later that these were the loopholes.",
    images: ["images/he.jpg", "images/he2.jpg"],
  };
  return (
    <>
      <MetaTags>
        <title>
          Hood Exhaust System Equipment Manufacturer in Siliguri - Shahil
          traders
        </title>
        <meta
          name="description"
          content="No1 Hood Exhaust System Kitchen Equipment Manufacturer in Siliguri"
        />
      </MetaTags>
      <MainPageTemplate>
        <ProjectComponent content={content} />
      </MainPageTemplate>
    </>
  );
};

export default HoodExhaustSystem;
