import React from "react";
import { Helmet } from "react-helmet";
import MainPageTemplate from "../element/MainPageTemplate";
import Banner from "../component/Banner";
import AboutSection from "../component/AboutSection";
import CategoriesSection from "../component/CategoriesSection";
import ClientSection from "../component/ClientSection";
import ProductSection from "../component/ProductSection";
import CountingSection from "../component/CountingSection";
import MissonVisson from "../component/MissonVisson";
import TestimonialSection from "../component/TestimonialSection";
import FooterSection from "../component/FooterSection";
import { MetaTags } from "react-meta-tags";

const Home = () => {
  return (
    <>
      <MetaTags>
        <title>
          Welcome to Shahil Traders Siliguri - Commercial Kitchen Equipment
          Manufacturer in Siliguri.
        </title>
        <meta
          name="description"
          content="Shahil Traders Leading manufacturer Of Commercial Kitchen Equipment in Siliguri North Bengal2024, Pantry, Food Service Equipment, Refrigeration, Bakery Equipment & Display Counter."
        />
      </MetaTags>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "url": "https://shahiltraders.com/",
              "name": "Shahil Traders",
              "alternateName": "Welcome to Shahil Traders Siliguri - Commercial Kitchen Equipment
      Manufacturer in Siliguri.",
              "logo": "https://shahiltraders.com/images/logoblue.png",
              "email":"sahiltraders@gmail.com",
              "areaServed":"IN",
              "sameAs": [
                  "https://goo.gl/maps/6tgbDYLPxJAVriGcA",
                  "https://jsdl.in/RSL-TNM1684351233",
                  "https://www.instagram.com/shahil_traders_/"
              ],
              "contactPoint": [{
                  "@type": "ContactPoint",
                  "telephone": "+91 9933810052",
                  "contactType": "customer service",
                  "areaServed": "IN"
              }]
            }
          `}
        </script>
      </Helmet>
      <MainPageTemplate>
        <Banner />
        <AboutSection showButton={true} />
        <CategoriesSection />
        <ClientSection />
        <ProductSection isActive={true} />
        <CountingSection />
        <MissonVisson />
        <TestimonialSection />
        <FooterSection />
      </MainPageTemplate>
    </>
  );
};

export default Home;
