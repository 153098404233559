import React from "react";
import { Link } from "react-router-dom";

const ProductSection = ({ isActive }) => {
  const products = [
    {
      imgsrc: "images/DC8.jpg",
      peragraph: "Display Counter",
      link: "/dislay-counter",
    },
    {
      imgsrc: "images/C1.jpg",
      peragraph: "Cooking Range",
      link: "/cooking-range",
    },
    {
      imgsrc: "images/S1.jpg",
      peragraph: "Service",
      link: "/services",
    },
    {
      imgsrc: "images/PF13.jpg",
      peragraph: "Pantry & Fast Food",
      link: "/pantry-and-fast-food",
    },
    {
      imgsrc: "images/P1.jpg",
      peragraph: "Preparation",
      link: "/preparation",
    },
    {
      imgsrc: "images/wp1.jpg",
      peragraph: "Wash Up",
      link: "/wash-up",
    },
    {
      imgsrc: "images/b1.jpg",
      peragraph: "Bakery",
      link: "/bakery",
    },
    {
      imgsrc: "images/CE1.jpg",
      peragraph: "Cold Equipment",
      link: "/cold-equipment",
    },
    {
      imgsrc: "images/he.jpg",
      peragraph: "Hood Exhaust System",
      link: "/hood-exhaust-system",
    },
    {
      imgsrc: "images/lpg1.jpg",
      peragraph: "LGP Banking",
      link: "/lpg-banking",
    },
  ];
  return (
    <div className="lg:px-16 md:px-8 sm:px-4">
      <div className="flex flex-col gap-8 justify-center items-center">
        <h1 className="lg:text-4xl sm:text-lg font-semibold md:text-2xl text-blue-900">
          Browse Our Product Catalog
        </h1>
        <div className=" grid xl:grid-cols-3 xxl:grid-cols-5 sm:grid-cols-2 md:grid-cols-3 sm:gap-4 lg:gap-8">
          {products.map((product, index) =>
            isActive ? (
              <Link
                to={product.link}
                className=" h-fit xl:w-[20rem] lg:w-[18rem] rounded-md  border-[1px] hover:border-2 hover:border-gray-700 border-gray-500 "
                key={index}
              >
                <div className=" sm:h-full lg:h-full gap-4 p-4 items-center justify-center flex flex-col">
                  <div className="  ">
                    <img
                      src={product.imgsrc}
                      loading="lazy"
                      alt=""
                      className="lg:h-[14rem] xl:w-auto lg:w-auto sm:h-[10rem] sm:w-[14rem] rounded-lg bg-cover"
                    />
                  </div>
                  <div className="lg:text-lg sm:text-sm text-blue-900 font-semibold">
                    {product.peragraph}
                  </div>
                </div>
              </Link>
            ) : (
              <div
                className=" h-fit xl:w-[20rem] lg:w-[18rem] rounded-md  border-[1px] border-gray-500 "
                key={index}
              >
                <div className=" sm:h-full lg:h-full gap-4 p-4 items-center justify-center flex flex-col">
                  <div className="  ">
                    <img
                      src={product.imgsrc}
                      loading="lazy"
                      alt=""
                      className="lg:h-[14rem] xl:w-auto lg:w-auto sm:h-[10rem] sm:w-[14rem] rounded-lg bg-cover"
                    />
                  </div>
                  <div className="lg:text-lg sm:text-sm text-blue-900 font-semibold">
                    {product.peragraph}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductSection;
