import React from "react";
import { Link } from "react-router-dom";
import Catalouge from "../assets/sahil-traders-digital-catalogue.pdf";
const TopHeader = () => {
  return (
    <div className="  fixed  bg-white z-[60] w-full">
      <div className="md:flex sm:hidden justify-between items-center sm:flex-col sm:gap-2 lg:gap-0 lg:flex-row p-4 px-8">
        <div className="">
          <img
            src="images/logoblue.png"
            alt=""
            className="h-[3rem] w-[10rem]"
          />
        </div>
        <div className="flex gap-4 text-white font-semibold sm:text-xs  md:text-lg">
          <Link
            to={Catalouge}
            download="Catalouge"
            target="_blank"
            className="sm:w-[10rem] rounded-sm md:w-[15rem] p-2 h-[3rem] bg-red-700 flex justify-center gap-4 items-center"
          >
            <span>Download Catalouge</span>
            <span>
              <img src="images/topnav2.svg" alt="" className="w-8 h-8" />
            </span>
          </Link>
          <Link
            to="/contact-us"
            className="sm:w-[10rem] rounded-sm md:w-[15rem] p-2 h-[3rem] bg-blue-900 flex justify-center gap-4 items-center"
          >
            <span>Enquiry Now</span>
            <img src="images/topnav1.svg" alt="" className="w-10 h-10" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
