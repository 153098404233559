import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const CategoriesSection = () => {
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 725) {
        setSlidesToShow(2);
      } else if (window.innerWidth <= 1024) {
        setSlidesToShow(3);
      } else if (window.innerWidth <= 1280) {
        setSlidesToShow(4);
      } else if (window.innerWidth <= 1780) {
        setSlidesToShow(5);
      } else {
        setSlidesToShow(6);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    arrows: false,
    centerMode: false,
  };
  const partners = [
    {
      imgsrc: "images/DC1.jpg",
      peragraph: "Display Counter",
    },
    {
      imgsrc: "images/product1.jpg",
      peragraph: "Cooking Range",
    },
    {
      imgsrc: "images/product2.jpg",
      peragraph: "Service",
    },
    {
      imgsrc: "images/product3.jpg",
      peragraph: "Pantry & Fast Food",
    },
    {
      imgsrc: "images/product4.jpg",
      peragraph: "Preparation",
    },
    {
      imgsrc: "images/product5.jpg",
      peragraph: "Wash Up",
    },
    {
      imgsrc: "images/product6.jpg",
      peragraph: "Bakery",
    },
    {
      imgsrc: "images/product7.jpg",
      peragraph: "Cold Equipment",
    },
    {
      imgsrc: "images/product8.jpg",
      peragraph: "Hood Exhaust System",
    },
    {
      imgsrc: "images/product9.jpg",
      peragraph: "LGP Banking",
    },
  ];
  return (
    <div className=" bg-gray-100">
      <div className="lg:p-16 sm:p-4 md:p-8 flex flex-col gap-8">
        <h1 className="w-full flex items-center justify-center font-semibold lg:text-4xl sm:text-lg md:text-2xl text-blue-900">
          Kitchen Equipment Categories
        </h1>
        <div className=" w-full flex items-center justify-center   ">
          <div className="lg:w-[90%] sm:w-full pl-4">
            <Slider {...settings}>
              {partners.map((partner, index) => (
                <div className=" " key={index}>
                  <div className="lg:w-[85%] sm:w-[95%] sm:h-full lg:h-full gap-4 items-center justify-center flex flex-col">
                    <div>
                      <img
                        src={partner.imgsrc}
                        loading="lazy"
                        alt=""
                        className="lg:h-[14rem] lg:w-[14rem] sm:h-[10rem] sm:w-[14rem] bg-cover"
                      />
                    </div>
                    <div className="lg:text-lg sm:text-sm text-blue-900 font-semibold">
                      {partner.peragraph}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesSection;
