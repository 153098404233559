import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductSection from "../component/ProductSection";
import { RiContractRightLine } from "react-icons/ri";
import {
  FaDownload,
  FaGoogle,
  FaInstagram,
  FaLocationDot,
} from "react-icons/fa6";
import { MdDoubleArrow, MdOutgoingMail } from "react-icons/md";
import { FaFacebookSquare, FaPhoneAlt, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import WhatssappPhoneButton from "../element/WhatssappPhoneButton";
import OnlyMobile from "../element/OnlyMobile";
import Scrolltop from "../element/Scrolltop";
import Catalouge from "../assets/sahil-traders-digital-catalogue.pdf";
import EnquirySection from "../component/EnquirySection";
import ReactPlayer from "react-player";
import { MetaTags } from "react-meta-tags";

const CommercialKitchenEquipmentManufacturer = () => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    dots: true,
    fade: true,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          textAlign: "center",
          width: "100%",
        }}
      >
        <ul style={{ margin: "0" }}> {dots} </ul>
      </div>
    ),
  };
  const slides = [
    {
      imageSrc: "/images/banner5.jpg",
    },
    {
      imageSrc: "/images/banner6.jpg",
    },
    {
      imageSrc: "/images/banner7.jpg",
    },
  ];
  const equipments = [
    {
      icons: "images/equipment4.svg",
      text: "Commercial Kitchen Equipment manufacturer in Siliguri",
    },
    {
      icons: "images/equipment3.svg",
      text: "Bakery Equipment Manufacturer",
    },
    {
      icons: "images/equipment1.svg",
      text: "Display Counter Manufacturer",
    },
    {
      icons: "images/equipment2.svg",
      text: "Cooking Range Service Manufacturer",
    },
  ];
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 725) {
        setSlidesToShow(3);
      } else if (window.innerWidth <= 1024) {
        setSlidesToShow(4);
      } else if (window.innerWidth <= 1780) {
        setSlidesToShow(5);
      } else {
        setSlidesToShow(6);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const settingsclient = {
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    arrows: false,
    centerMode: false,
  };
  const clients = [
    { imgsrc: "images/client1.png" },

    { imgsrc: "images/client2.jpg" },
    { imgsrc: "images/client3.png" },
    { imgsrc: "images/client4.png" },
    { imgsrc: "images/client5.png" },
    { imgsrc: "images/client6.png" },
    { imgsrc: "images/client7.png" },
    { imgsrc: "images/client8.jpeg" },
    { imgsrc: "images/client9.png" },

    { imgsrc: "images/client12.png" },
    { imgsrc: "images/client13.png" },
    { imgsrc: "images/client14.png" },
    { imgsrc: "images/client10.jpg" },
    { imgsrc: "images/client11.png" },

    { imgsrc: "images/client14.png" },
  ];
  const products = [
    { name: "Display Counter Manufacturer in Siliguri" },

    { name: "Cooking Range Service Manufacturer" },
    { name: " Pantry & Fast Food Manufacturer " },

    { name: "Preparation Manufacturer in Siliguri" },
    { name: "Wash UpEquipment  Manufacturer" },
    { name: "Bakery Equipment Manufacturer" },
    { name: "Cold Equipment Manufacturer" },
    { name: "Hood Exhaust System Manufacturer" },
    { name: "LGP Banking Manufacturer" },
  ];
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <MetaTags>
        <title>
          Shahil Traders - Commercial Kitchen Equipment Manufacturer in Siliguri
        </title>
        <meta
          name="description"
          content="Commercial Kitchen Equipment, Display Counter Food Service  | Pantry Equipment manufacturer in Siliguri"
        />
      </MetaTags>
      <div className="flex font-ovo w-full h-full flex-col  overflow-x-hidden">
        <header className="w-full bg-white sm:p-2 lg:p-4 px-8 flex flex-row  justify-between items-center">
          <div className="">
            <img
              src="images/logoblue.png"
              alt=""
              className="lg:h-[3rem] sm:h-[2rem] sm:w-[6rem] lg:w-[8rem]"
            />
          </div>
          <div className="flex gap-4 text-white font-semibold sm:text-[13px]  md:text-lg">
            <Link
              to={Catalouge}
              download="Catalouge"
              target="_blank"
              className="sm:w-[10rem] rounded-sm md:w-[15rem] p-2 lg:h-[3rem] sm:h-[2rem] bg-red-700 flex justify-center gap-4 items-center"
            >
              <span>Our Catalouge</span>
              <span>
                <img
                  src="images/topnav2.svg"
                  alt=""
                  className="lg:w-8 lg:h-8 sm:h-5 sm:w-5"
                />
              </span>
            </Link>
          </div>
        </header>
        <div className="w-full">
          <Slider {...settings}>
            {slides.map((slide, index) => (
              <div className="carousel-item relative bg-gray-600 " key={index}>
                <div className="w-full relative ">
                  <img
                    src={slide.imageSrc}
                    loading="lazy"
                    alt=""
                    className="w-full resize  sm:h-auto md:h-auto opacity-80"
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="w-full lg:p-16 sm:p-4 md:p-8 flex justify-center items-center">
          <div className="grid lg:grid-cols-2 sm:grid-cols-1 sm:gap-2 lg:gap-5 gap-x-20 place-content-center items-center">
            {equipments.map((equipment, index) => (
              <d
                className="flex sm:flex-col text-center lg:flex-row items-center  gap-2  lg:text-xl font-semibold sm:text-xl "
                key={index}
              >
                <div>
                  <img
                    src={equipment.icons}
                    alt=""
                    className="md:h-16 md:w-16 sm:w-12 sm:h-12"
                  />
                </div>
                <div>{equipment.text}</div>
              </d>
            ))}
          </div>
        </div>
        <div className=" w-full mt-8 flex flex-col items-center sm:gap-4 lg:gap-10 justify-center  lg:px-16 sm:px-4 md:px-8 ">
          <span className="lg:text-4xl sm:text-lg md:text-2xl font-semibold text-blue-900">
            Over 1000+ top Clients work with us
          </span>
          <div className="w-[90%] pl-4">
            <Slider {...settingsclient}>
              {clients.map((client, index) => (
                <div className="" key={index}>
                  <div className="border-2 sm:p-2 lg:p-4 border-gray-200 flex items-center justify-center xxl:w-[14rem] xxl:h-[14rem] lg:w-[10rem] lg:h-[10rem] sm:h-[6rem] sm:w-[6rem]">
                    <img src={client.imgsrc} alt="" className="" />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="flex sm:flex-col md:flex-row-reverse sm:p-4 md:p-8 lg:p-16 sm:gap-5 lg:gap-10 justify-center items-center">
          <div className="lg:w-[30%] sm:w-full flex text-blue-950 text-lg flex-col gap-2">
            <div>
              <EnquirySection />
            </div>
          </div>
          <div className="lg:w-[60%] sm:w-full h-full  flex justify-center items-center">
            <div className="flex flex-col  gap-4 items-center ">
              <div className="flex flex-col gap-6 sm:w-full justify-center items-center">
                <div className="flex flex-col gap-2 w-[90%]">
                  <span className="md:text-3xl sm:text-center lg:text-start sm:text-xl  font-semibold text-blue-900">
                    Welcome To Shahil Traders
                  </span>
                  <span className="md:text-xl lg:text-start sm:text-center sm:text-base">
                    Shahil Traders, based in Siliguri, is a renowned name in the
                    field of commercial kitchen equipment manufacturing. With a
                    rich history and a commitment to quality, they have
                    established themselves as one of the oldest and most trusted
                    kitchen equipment manufacturers in Siliguri.
                  </span>
                </div>
              </div>
              <div className="sm:w-full flex items-center justify-center relative ">
                <div
                  onClick={() => openModal()}
                  className="bg-gray-800 w-[90%] h-fit rounded-lg cursor-pointer"
                >
                  <img
                    src="images/about1.jpg"
                    alt=""
                    className="w-full sm:h-[15rem] md:h-auto lg:h-[30rem] rounded-lg  opacity-70 "
                  />
                </div>
                <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <img
                    onClick={() => openModal()}
                    src="images/play1.svg"
                    alt=""
                    className="w-[6rem] h-[6rem] cursor-pointer "
                  />
                </span>
              </div>

              {showModal && (
                <div className="fixed top-0 left-0 w-full h-full z-[100] flex justify-center items-center bg-black bg-opacity-80">
                  <div className="relative w-[75%] sm:h-[20rem] md:h-[30rem] lg:h-[40rem] flex justify-center items-center">
                    <ReactPlayer
                      url="https://www.youtube.com/watch?v=9ShsRPBayXI&ab_channel=FusionLeafGroup"
                      width="100%"
                      height="100%"
                      controls={true}
                    />
                  </div>
                  <div className="top-0 absolute right-0 p-16">
                    <FaTimes
                      className="text-white text-3xl cursor-pointer"
                      onClick={closeModal}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <ProductSection isActive={true} />
        <footer className="bg-blue-900 mt-4">
          <div className="lg:p-16 sm:p-4 md:p-8 h-full">
            <div className=" flex-col flex items-center justify-center">
              <div className="grid lg:grid-cols-3 sm:grid-cols-1 sm:gap-8 lg:gap-16 ">
                <div className="md:w-[85%] sm:w-full  h-fit rounded-lg shadow-xl bg-white sm:p-4 xl:p-8 flex flex-col gap-4">
                  <div>
                    <img
                      src="images/logoblue.png"
                      alt=""
                      className="w-[10rem] h-auto"
                    />
                  </div>
                  <div className="flex gap-2 md:text-xl sm:text-lg items-start">
                    <span>
                      <FaLocationDot />
                    </span>
                    <span>
                      Company Address:- Debidanga, Champasari, Siliguri,
                      Landmark - Behind Maa Durga Petrol Pump, West Bengal
                      734003
                    </span>
                  </div>
                  <div className="flex gap-2 items-center md:text-xl sm:text-lg">
                    <span>
                      <MdOutgoingMail />
                    </span>
                    <Link to="mailto:shahiltraders@gmail.com">
                      shahiltraders@gmail.com
                    </Link>
                  </div>
                  <div className="flex gap-2 items-center md:text-xl sm:text-sm">
                    <span>
                      <FaPhoneAlt />
                    </span>
                    <Link to="tel:9933810052">9933810052</Link>
                    <span>/</span>
                    <Link to="tel:9933810058">9933810058 </Link>
                  </div>
                  <div className="flex gap-2 items-center border-t-2 pt-2 text-xl">
                    <div className="">Follow Us: </div>
                    <div className="flex gap-4 ">
                      <Link to="https://www.instagram.com/shahil_traders_/">
                        <img
                          src="images/instagramlogo.svg"
                          alt=""
                          className="w-6 h-5"
                        />
                      </Link>
                      <Link to="https://goo.gl/maps/6tgbDYLPxJAVriGcA">
                        <img
                          src="images/googlelogo.svg"
                          alt=""
                          className="w-6 h-5"
                        />
                      </Link>
                      <Link to="https://www.facebook.com/people/Shahil-traders/100070165681143/ ">
                        <img
                          src="images/facebooklogo.svg"
                          alt=""
                          className="w-6 h-5"
                        />
                      </Link>
                      <Link
                        className=" "
                        to="https://www.facebook.com/people/Shahil-traders/100070165681143/ "
                      >
                        <img
                          src="images/justdiallogo.png"
                          alt=""
                          className="w-6 h-5"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex sm:flex-col lg:flex-row sm:gap-8 lg:gap-0 lg:justify-between">
                  <div className="flex flex-col gap-4 text-white">
                    <span className="text-3xl font-bold text-center">
                      Our Products
                    </span>

                    <div className="grid lg:grid-cols-1 sm:grid-cols-1 gap-4 sm:text-lg md:text-lg lg:text-xl">
                      {products.map((product, index) => (
                        <div
                          className="flex flex-row gap-2 items-center"
                          key={index}
                        >
                          <span>
                            <MdDoubleArrow />
                          </span>
                          <span>{product.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <div className="md:w-[50%] lg:w-[75%] sm:w-full md:h-[12rem] lg:h-[10rem]">
                    <iframe
                      title="map"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28505.787900357063!2d88.407302!3d26.7372434!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e440e99b6e02d7%3A0x42e90be2d70dc3b1!2sShahil%20Traders!5e0!3m2!1sen!2sin!4v1711091659545!5m2!1sen!2sin"
                      loading="lazy"
                      className="w-full h-full rounded-lg"
                    ></iframe>
                  </div>
                  <Link
                    to={Catalouge}
                    download="Catalouge"
                    target="_blank"
                    className="lg:w-[75%] md:w-[50%] sm:w-[75%]  p-4 text-white text-xl font-semibold bg-blue-950 flex items-center justify-between"
                  >
                    <span>Download Catalouge</span>
                    <span>
                      <FaDownload />
                    </span>
                  </Link>
                  <Link
                    to="/"
                    className="lg:w-[75%] md:w-[50%] sm:w-[75%]  p-4 text-white text-xl font-semibold bg-blue-950 flex items-center justify-between"
                  >
                    <span>Go To Home Page</span>
                    <span>
                      <RiContractRightLine />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="flex md:flex-row md:h-fit sm:h-[9rem] sm:flex-col sm:text-xs md:text-lg lg:text-xl sm:justify-start items-center md:justify-between bg-blue-950  sm:p-2 md:p-6 ">
            <div className="  text-white    flex   ">
              Copyrights © Shahil Traders, 2024.
            </div>
            <div className="  text-white      flex gap-2  ">
              <span>Developed By:</span>
              <Link to="https://rebootmarketing.in/">
                Reboot Marketing Pvt. Ltd.
              </Link>
            </div>
          </div>
        </footer>
        <div>
          <WhatssappPhoneButton />
        </div>
        <div>
          <OnlyMobile />
        </div>
        <div>
          <Scrolltop />
        </div>
      </div>
    </>
  );
};

export default CommercialKitchenEquipmentManufacturer;
