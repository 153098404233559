import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import { Link } from "react-router-dom";
import AboutSection from "../component/AboutSection";
import CountingSection from "../component/CountingSection";
import MissonVisson from "../component/MissonVisson";
import CategoriesSection from "../component/CategoriesSection";
import FooterSection from "../component/FooterSection";
import { MetaTags } from "react-meta-tags";

const AboutPage = () => {
  return (
    <>
      <MetaTags>
        <title>
          About us to Shahil Traders Siliguri - Commercial Kitchen Equipment
          Manufacturer in Siliguri.
        </title>
        <meta
          name="description"
          content="Shahil Traders Leading manufacturer Of Commercial Kitchen Equipment in Siliguri North Bengal2024, Pantry, Food Service Equipment, Refrigeration, Bakery Equipment & Display Counter."
        />
      </MetaTags>

      <MainPageTemplate>
        <div className="bg-gray-200 w-full  flex items-center justify-start p-4">
          <span className="flex gap-2 text-lg">
            <Link to="/">Home</Link>
            <span>/</span>
            <span>About Us</span>
          </span>
        </div>
        <AboutSection showButton={false} />
        <CountingSection />
        <MissonVisson />
        <CategoriesSection />
        <FooterSection />
      </MainPageTemplate>
    </>
  );
};

export default AboutPage;
