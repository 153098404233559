import React, { useState } from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutgoingMail } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";
import FooterSection from "../component/FooterSection";
import emailjs from "@emailjs/browser";
import { MetaTags } from "react-meta-tags";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
    requirement: "",
    location: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, phone, requirement } = formData;

    // Check if all required fields are filled
    if (!name || !phone || !requirement) {
      alert("Please fill in all required fields.");
      return;
    }

    emailjs
      .sendForm(
        "service_aubw0qb",
        "template_3kzg8jz",
        e.target,
        "AVANVIxmyNCybjqq-"
      )
      .then(
        () => {
          alert("We are connecting you.");
        },
        (error) => {
          console.log(error.text);
        }
      );

    // Clear the form data after submission
    setFormData({
      name: "",
      phone: "",
      message: "",
      requirement: "",
      location: "",
    });
  };
  return (
    <>
      <MetaTags>
        <title>Contact us to Shahil Traders in Siliguri</title>
        <meta
          name="description"
          content="Shahil Traders Leading manufacturer Of Commercial Kitchen Equipment in Siliguri North Bengal2024, Pantry, Food Service Equipment, Refrigeration, Bakery Equipment & Display Counter."
        />
      </MetaTags>
      <MainPageTemplate>
        <div className="bg-gray-200 w-full  flex items-center justify-start p-4">
          <span className="flex gap-2 text-lg">
            <Link to="/">Home</Link>
            <span>/</span>
            <span>Contact Us</span>
          </span>
        </div>
        <div className="lg:p-16 md:p-8 sm:p-4 flex flex-col justify-center sm:gap-5 lg:gap-10 items-center">
          <div className="text-2xl text-blue-950 font-semibold">
            Adreess Our Factory & Company
          </div>
          <div className="flex md:flex-row sm:flex-col justify-center sm:gap-5 lg:gap-10 items-center">
            <div className="flex sm:w-full lg:w-[40%] lg:text-xl  md:text-lg sm:text-sm rounded-lg flex-col  p-4 border-2 border-gray-300 gap-6">
              <div className="flex gap-2  items-start">
                <span>
                  <FaLocationDot />
                </span>
                <span className="flex flex-wrap gap-1">
                  <span> Company Address:-</span>
                  <span>Champasari Main Road,</span>
                  <span> Landmark - Near Maa Bhawani Marble,</span>
                  <span>Siliguri , 734003</span>
                </span>
              </div>
              <div className="flex gap-2 items-center ">
                <span>
                  <MdOutgoingMail />
                </span>
                <span>sahiltraders@gmail.com</span>
              </div>
              <div className="flex gap-2 items-center ">
                <span>
                  <FaPhoneAlt />
                </span>
                <span>+91 9933810052</span>
              </div>
              <div className="flex gap-2 items-center border-t-2 pt-2 text-xl">
                <Link
                  target="_Blank"
                  to="https://goo.gl/maps/6tgbDYLPxJAVriGcA"
                  className="w-[10rem] p-2 rounded-md items-center text-lg flex justify-between h-fit bg-blue-900 text-white"
                >
                  <span>
                    <SlLocationPin />
                  </span>
                  <span>Get Direction</span>
                </Link>
              </div>
            </div>
            <div className="flex sm:w-full lg:w-[40%] rounded-lg lg:text-xl  md:text-lg sm:text-sm flex-col  p-4 border-2 border-gray-300 gap-6">
              <div className="flex gap-2  items-start">
                <span>
                  <FaLocationDot />
                </span>
                <span className="flex flex-wrap gap-1">
                  <span> Factory Address:-</span>
                  <span>Debidanga,</span>{" "}
                  <span> Behind Maa Durga Petrol Pump,</span>
                  <span> Champasari,</span>
                  <span>Pradhan Nagar, Siliguri 734003</span>
                </span>
              </div>
              <div className="flex gap-2 items-center">
                <span>
                  <MdOutgoingMail />
                </span>
                <span>sahiltraders@gmail.com</span>
              </div>
              <div className="flex gap-2 items-center ">
                <span>
                  <FaPhoneAlt />
                </span>
                <span>+91 9933810058 </span>
              </div>
              <div className="flex gap-2 items-center border-t-2 pt-2 text-xl">
                <Link
                  target="_Blank"
                  to="https://goo.gl/maps/6tgbDYLPxJAVriGcA"
                  className="w-[10rem] p-2 rounded-md items-center text-lg flex justify-between h-fit bg-blue-900 text-white"
                >
                  <span>
                    <SlLocationPin />
                  </span>
                  <span>Get Direction</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex sm:flex-col md:flex-row sm:p-4 md:p-8 lg:p-16 sm:gap-5 lg:gap-10 justify-center items-center">
          <div className="lg:w-[45%] sm:w-full flex text-blue-950 text-lg flex-col gap-2">
            <div className="text-2xl text-center text-blue-950 font-semibold">
              SEND AN ENQUIRY
            </div>
            <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="name"
                className="w-full h-[4rem] rounded-lg p-4 bg-blue-100 border-2 border-gray-800"
              />
              <input
                type="Mobile"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Mob No"
                className="w-full h-[4rem] rounded-lg p-4 bg-blue-100 border-2 border-gray-800"
              />
              <select
                name="requirement"
                value={formData.requirement}
                onChange={handleChange}
                className="w-full h-[4rem] rounded-lg p-4 bg-blue-100 border-2 border-gray-800"
              >
                <option value="">Select Requirement</option>
                <option value="Display Counter">Display Counter</option>
                <option value="Cooking Range">Cooking Range</option>
                <option value="Service">Service</option>
                <option value="Pantry & Fast Food">Pantry & Fast Food</option>
                <option value="Preparation">Preparation</option>
                <option value="Wash Up">Wash Up</option>
                <option value="Bakery">Bakery</option>
                <option value="Cold Equipment">Cold Equipment</option>
                <option value="Hood Exhaust System">Hood Exhaust System</option>
                <option value="LGP Banking">LGP Banking</option>
              </select>

              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
                placeholder="Location (optional)"
                className="w-full h-[4rem] rounded-lg p-4 bg-blue-100 border-2 border-gray-800"
              />
              <textarea
                type="text"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Massage (optional)"
                className="w-full max-h-[8rem] min-h-[8rem] rounded-lg p-4 bg-blue-100 border-2 border-gray-800"
              />
              <button
                type="submit"
                className="w-[10rem] sm:h-[3rem] lg:h-[3rem] border-[1px] text-white text-lg font-bold border-gray-300 bg-blue-900 flex justify-center items-center rounded-lg"
              >
                Get Best Price
              </button>
            </form>
          </div>
          <div className="lg:w-[45%] sm:w-full h-full  flex justify-center items-center">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28505.787900357063!2d88.407302!3d26.7372434!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e440e99b6e02d7%3A0x42e90be2d70dc3b1!2sShahil%20Traders!5e0!3m2!1sen!2sin!4v1711091659545!5m2!1sen!2sin"
              loading="lazy"
              className="w-full h-[25rem]  rounded-lg"
            ></iframe>
          </div>
        </div>
        <FooterSection />
      </MainPageTemplate>
    </>
  );
};

export default ContactUs;
