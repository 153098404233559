import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ProjectComponent from "../component/ProjectComponent";
import { MetaTags } from "react-meta-tags";

const Preparation = () => {
  const content = {
    title: "Preparation",
    description:
      "The first impression matters a lot. So it is with display counter. Such display counters are used in many restaurants as they are space-efficient and easy to manage. Now, most of us often get confused about where to buy and how to buy. Most display counters (डिस्प्ले काउंटर) lag somewhere, the customer first buys and realizes it later that these were the loopholes.",
    images: [
      "images/P1.jpg",
      "images/P2.jpg",
      "images/P3.jpg",
      "images/P4.jpg",
      "images/P5.jpg",
      "images/P6.jpg",
      "images/P7.jpg",
      "images/P8.jpg",

      "images/P12.jpg",

      "images/P16.jpg",
    ],
  };
  return (
    <>
      <MetaTags>
        <title>
          Preparation Equipment Manufacturer in Siliguri - Shahil traders
        </title>
        <meta
          name="description"
          content="No1 Preparation Kitchen Equipment Manufacturer in Siliguri"
        />
      </MetaTags>
      <MainPageTemplate>
        <ProjectComponent content={content} />
      </MainPageTemplate>
    </>
  );
};

export default Preparation;
