import React, { useEffect, useRef } from "react";
import bannerbg from "../assets/bannervideo.mp4";

function Banner() {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    video.play().catch((error) => {});
  }, []);

  return (
    <div className="">
      <div className="">
        <div className="w-full   ">
          <video
            ref={videoRef}
            src={bannerbg}
            loop
            autoPlay
            playsInline
            type="video/mp4"
            className="w-full "
            muted
          />
        </div>
      </div>
    </div>
  );
}

export default Banner;
