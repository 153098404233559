import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ProjectComponent from "../component/ProjectComponent";
import { MetaTags } from "react-meta-tags";

const ColdEquipmnt = () => {
  const content = {
    title: "Cold Equipmnt",
    description:
      "The first impression matters a lot. So it is with display counter. Such display counters are used in many restaurants as they are space-efficient and easy to manage. Now, most of us often get confused about where to buy and how to buy. Most display counters (डिस्प्ले काउंटर) lag somewhere, the customer first buys and realizes it later that these were the loopholes.",
    images: [
      "images/CE1.jpg",
      "images/CE2.jpg",
      "images/CE3.jpg",
      "images/CE4.jpg",
      "images/CE5.jpg",
      "images/CE6.jpg",
      "images/CE7.jpg",
      "images/CE9.jpg",
      "images/CE10.jpg",
      "images/CE12.jpg",
      "images/CE13.jpg",
      "images/CE15.jpg",
    ],
  };
  return (
    <>
      <MetaTags>
        <title>Cold Equipment Manufacturer in Siliguri - Shahil traders</title>
        <meta
          name="description"
          content="No1 Cold Kitchen Equipment Manufacturer in Siliguri"
        />
      </MetaTags>
      <MainPageTemplate>
        <ProjectComponent content={content} />
      </MainPageTemplate>
    </>
  );
};

export default ColdEquipmnt;
