import React from "react";

import { Link } from "react-router-dom";
import EnquirySection from "./EnquirySection";
import ProductLinkSection from "./ProductLinkSection";
import { FaPhoneAlt } from "react-icons/fa";
import FooterSection from "./FooterSection";

const ProjectComponent = ({ content }) => {
  const { title, description, images } = content;

  return (
    <div className="">
      <div className="bg-gray-200 w-full  flex items-center justify-start p-4">
        <span className="flex gap-2 text-lg">
          <Link to="/">Home</Link>
          <span>/</span>
          <span>{title}</span>
        </span>
      </div>
      <div className="lg:p-16 md:p-8 sm:p-8 flex flex-col gap-16 ">
        <div className="w-full flex flex-col gap-4 items-center justify-center">
          <span className="lg:text-3xl sm:text-xl font-semibold">{title}</span>

          <span className="text-center sm:text-lg lg:text-xl">
            {description}
          </span>
        </div>
      </div>
      <div className="w-full  flex md:flex-row sm:flex-col sm:gap-4  lg:gap-16 lg:p-16 md:p-8 sm:p-4">
        <div className="lg:w-[70%] md:w-[60%] sm:w-full overflow-auto ">
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 sm:gap-2 lg:gap-8 ">
            {images.map((image, index) => (
              <span
                key={index}
                className="w-[100%] h-auto justify-center items-center flex border-[1px] border-gray-200"
              >
                <img src={image} alt="" />
              </span>
            ))}
          </div>
        </div>
        <div className="lg:w-[27%] md:w-[40%]  h-fit  sm:w-full flex flex-col sm:gap-4 md:gap-8 lg:gap-16">
          <div>
            <EnquirySection showcatalouge={true} />
          </div>
          <div className="flex flex-col items-center justify-center sm:p-4 lg:p-8 gap-4">
            <span className="flex flex-col gap-1">
              <span className="flex gap-1 text-blue-900  items-center sm:text-sm lg:text-2xl font-semibold">
                <span>
                  <FaPhoneAlt />
                </span>
                <span>TRADE ENQUIRY :</span>
              </span>
              <span className="text-center text-xl text-sky-600">
                +91 9933810052
              </span>
            </span>
            <span className="flex flex-col gap-1">
              <span className="flex gap-1 text-blue-900 items-center sm:text-xl lg:text-2xl font-semibold">
                <span>
                  <FaPhoneAlt />
                </span>
                <span> CUSTOMER CARE :</span>
              </span>
              <span className="text-center text-xl text-sky-600">
                +91 9933810058{" "}
              </span>
            </span>
          </div>
          <div>
            <ProductLinkSection />
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  );
};

export default ProjectComponent;
