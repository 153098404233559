import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ProjectComponent from "../component/ProjectComponent";
import { MetaTags } from "react-meta-tags";

const WashUp = () => {
  const content = {
    title: "Wash Up",
    description:
      "The first impression matters a lot. So it is with display counter. Such display counters are used in many restaurants as they are space-efficient and easy to manage. Now, most of us often get confused about where to buy and how to buy. Most display counters (डिस्प्ले काउंटर) lag somewhere, the customer first buys and realizes it later that these were the loopholes.",
    images: [
      "images/wp1.jpg",
      "images/wp2.jpg",
      "images/wp3.jpg",
      "images/wp4.jpg",
      "images/wp5.jpg",
      "images/wp6.jpg",
      "images/wp7.jpg",
      "images/wp8.jpg",
    ],
  };
  return (
    <>
      <MetaTags>
        <title>
          Wash Up Equipment Manufacturer in Siliguri - Shahil traders
        </title>
        <meta
          name="description"
          content="No1 Wash Up Kitchen Equipment Manufacturer in Siliguri"
        />
      </MetaTags>
      <MainPageTemplate>
        <ProjectComponent content={content} />
      </MainPageTemplate>
    </>
  );
};

export default WashUp;
