import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ProjectComponent from "../component/ProjectComponent";
import { MetaTags } from "react-meta-tags";

const LgpBanking = () => {
  const content = {
    title: "LGP Banking",
    description:
      "The first impression matters a lot. So it is with display counter. Such display counters are used in many restaurants as they are space-efficient and easy to manage. Now, most of us often get confused about where to buy and how to buy. Most display counters (डिस्प्ले काउंटर) lag somewhere, the customer first buys and realizes it later that these were the loopholes.",
    images: ["images/lpg1.jpg", "images/lpg2.jpg"],
  };
  return (
    <>
      <MetaTags>
        <title>
          LGP Banking Equipment Manufacturer in Siliguri - Shahil traders
        </title>
        <meta
          name="description"
          content="No1 LGP Banking Kitchen Equipment Manufacturer in Siliguri"
        />
      </MetaTags>
      <MainPageTemplate>
        <ProjectComponent content={content} />
      </MainPageTemplate>
    </>
  );
};

export default LgpBanking;
