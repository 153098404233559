import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ProjectComponent from "../component/ProjectComponent";
import { MetaTags } from "react-meta-tags";

const CookingRange = () => {
  const content = {
    title: "Cooking Range",
    description:
      "The first impression matters a lot. So it is with display counter. Such display counters are used in many restaurants as they are space-efficient and easy to manage. Now, most of us often get confused about where to buy and how to buy. Most display counters (डिस्प्ले काउंटर) lag somewhere, the customer first buys and realizes it later that these were the loopholes.",
    images: [
      "images/C1.jpg",
      "images/C2.jpg",
      "images/C3.jpg",
      "images/C4.jpg",
      "images/C5.jpg",
      "images/C6.jpg",
      "images/C7.jpg",
      "images/C8.jpg",
      "images/C9.jpg",
      "images/C10.jpg",
      "images/C11.jpg",
      "images/C12.jpg",
      "images/C13.jpg",
      "images/C14.jpg",
      "images/C17.jpg",
      "images/CB15.jpg",
      "images/CB16.jpg",
      "images/CB19.jpg",
    ],
  };
  return (
    <>
      <MetaTags>
        <title>
          Cooking Range Equipment Manufacturer in Siliguri - Shahil traders
        </title>
        <meta
          name="description"
          content="No1 Cooking Range Kitchen Equipment Manufacturer in Siliguri"
        />
      </MetaTags>
      <MainPageTemplate>
        <ProjectComponent content={content} />
      </MainPageTemplate>
    </>
  );
};

export default CookingRange;
