import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { FaTimes } from "react-icons/fa";
import { MdPlayCircle } from "react-icons/md";

const AboutSection = ({ showButton }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div className="lg:p-16 sm:p-4 md:p-8">
      <div className="flex lg:flex-row sm:flex-col after: gap-4 items-center ">
        <div className="lg:w-[50%] sm:w-full flex items-center justify-center relative ">
          <div
            onClick={() => openModal()}
            className="bg-gray-800 w-[90%] h-fit rounded-lg cursor-pointer"
          >
            <img
              src="images/about1.jpg"
              alt=""
              className="w-full sm:h-[15rem] md:h-auto rounded-lg  opacity-70 "
            />
          </div>
          <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <img
              onClick={() => openModal()}
              src="images/play1.svg"
              alt=""
              className="w-[6rem] h-[6rem] cursor-pointer "
            />
          </span>
        </div>

        {showModal && (
          <div className="fixed top-0 left-0 w-full h-full z-[100] flex justify-center items-center bg-black bg-opacity-80">
            <div className="relative w-[75%] sm:h-[20rem] md:h-[30rem] lg:h-[40rem] flex justify-center items-center">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=9ShsRPBayXI&ab_channel=FusionLeafGroup"
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
            <div className="top-0 absolute right-0 p-16">
              <FaTimes
                className="text-white text-3xl cursor-pointer"
                onClick={closeModal}
              />
            </div>
          </div>
        )}
        <div className="flex flex-col gap-6 lg:w-[50%] sm:w-full">
          <div className="flex flex-col gap-2">
            <h1 className="md:text-3xl sm:text-xl text-center font-semibold text-blue-900">
              Welcome To Shahil Traders
            </h1>
            <h2 className="md:text-xl sm:text-base">
              Shahil Traders, based in Siliguri, is a renowned name in the field
              of commercial kitchen equipment manufacturing. With a rich history
              and a commitment to quality, they have established themselves as
              one of the oldest and most trusted kitchen equipment manufacturers
              in Siliguri.
            </h2>
          </div>
          <div className="flex flex-col gap-2">
            <h1 className="md:text-3xl sm:text-xl text-center font-semibold text-blue-900">
              Commercial Kitchen Equipment Manufacturer
            </h1>
            <h2 className="md:text-xl sm:text-base">
              Specializing in stainless steel kitchen and bakery equipment,
              Shahil Traders caters to a diverse range of industries including
              hotels, bars, sweet parlors, canteens, bakeries, hospitals,
              clinics, and more. Their extensive product range includes display
              counters, deep fridges, refrigerators, ventilation systems, gas
              pipeline installation, and various other modern commercial kitchen
              and bakery equipment.
            </h2>
          </div>
          {showButton && (
            <div className="w-full justify-center items-center flex">
              <Link
                to="/about-us"
                className="w-[8rem] bg-blue-950 text-white rounded-xl text-lg font-medium h-[3rem] flex items-center justify-center"
              >
                Read More
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
