import React, { useEffect } from "react";
import Header from "../component/Header";
import TopHeader from "../component/TopHeader";
import WhatssappPhoneButton from "./WhatssappPhoneButton";
import Scrolltop from "./Scrolltop";
import OnlyMobile from "./OnlyMobile";

const MainPageTemplate = ({ children }) => {
  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="flex font-ovo w-full h-full flex-col  overflow-x-hidden ">
        <div>
          <TopHeader />
        </div>
        <div className="lg:mt-[5rem] md:mt-[8.5rem]">
          <Header />
        </div>
        <div className="font-ovo sm:mt-[3rem] md:mt-[4rem] lg:mt-[3.5rem]">
          {children}
        </div>
        <div>
          <WhatssappPhoneButton />
        </div>
        <div>
          <OnlyMobile />
        </div>
        <div>
          <Scrolltop />
        </div>
      </div>
    </div>
  );
};

export default MainPageTemplate;
