import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const MissonVisson = () => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };
  const slides = [
    {
      imageSrc: "/images/banner5.jpg",
    },
    {
      imageSrc: "/images/banner6.jpg",
    },
    {
      imageSrc: "/images/banner7.jpg",
    },
  ];
  return (
    <div className="lg:p-16 sm:p-4 md:p-8">
      <div className="flex lg:flex-row-reverse sm:flex-col gap-4 items-center ">
        <div className="lg:w-[50%] rounded-lg sm:w-full flex items-center justify-center">
          <div className="w-full">
            <Slider {...settings}>
              {slides.map((slide, index) => (
                <div className="carousel-item relative rounded-lg " key={index}>
                  <div className="w-full relative ">
                    <img
                      src={slide.imageSrc}
                      loading="lazy"
                      alt=""
                      className="w-full resize rounded-lg  sm:h-auto md:h-auto "
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="flex flex-col gap-6 lg:w-[50%] sm:w-full">
          <div className="flex flex-col gap-2">
            <span className="md:text-3xl sm:text-xl font-semibold text-blue-900">
              Simplifying Kitchen Workflows with Premium Equipment
            </span>
            <span className="md:text-xl sm:text-base">
              Shahil Traders, based in Siliguri, is a renowned name in the field
              of commercial kitchen equipment manufacturing. With a rich history
              and a commitment to quality, they have established themselves as
              one of the oldest and most trusted kitchen equipment manufacturers
              in Siliguri.
            </span>
          </div>
          <div className="flex flex-col gap-2">
            <span className="md:text-3xl sm:text-xl  font-semibold text-blue-900">
              Setting the Standard for Efficiency and Precision in Culinary Arts
            </span>
            <span className="md:text-xl sm:text-base">
              Specializing in stainless steel kitchen and bakery equipment,
              Shahil Traders caters to a diverse range of industries including
              hotels, bars, sweet parlors, canteens, bakeries, hospitals,
              clinics, and more. Their extensive product range includes display
              counters, deep fridges, refrigerators, ventilation systems, gas
              pipeline installation, and various other modern commercial kitchen
              and bakery equipment.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissonVisson;
