import React from "react";
import CountUp from "react-countup";

const CountingSection = () => {
  return (
    <div className="bg-gray-200 lg:mt-16 md:mt-8 sm:mt-4">
      <div className="md:p-8 sm:p-4">
        <div className="flex md:flex-row sm:flex-row sm:gap-8 md:gap-20 justify-center items-center">
          <div className="flex flex-row sm:gap-8 md:gap-20 items-center">
            <div className="flex lg:flex-row sm:flex-col justify-center  gap-2 items-center ">
              <span>
                <img
                  src="images/counting1.svg"
                  alt=""
                  className="md:h-16 md:w-16 sm:w-10 sm:h-10"
                />
              </span>
              <span className="flex flex-col lg:items-start sm:items-center lg:justify-start sm:justify-center">
                <CountUp
                  end={1500}
                  duration={2}
                  enableScrollSpy
                  className="lg:text-2xl md:text-lg sm:text-sm"
                />
                <span className="lg:text-2xl md:text-lg sm:text-[8px] text-blue-900 font-semibold">
                  PROJECTS COMPLETED
                </span>
              </span>
            </div>
            <div className="flex lg:flex-row sm:flex-col gap-2 items-center ">
              <span>
                <img
                  src="images/counting3.svg"
                  alt=""
                  className="md:h-16 md:w-16 sm:w-10 sm:h-10"
                />
              </span>
              <span className="flex flex-col lg:items-start sm:items-center lg:justify-start sm:justify-center">
                <CountUp
                  end={102}
                  duration={2}
                  enableScrollSpy
                  className="lg:text-2xl md:text-lg sm:text-sm"
                />
                <span className="lg:text-2xl md:text-lg sm:text-[8px] text-blue-900 font-semibold">
                  AWARDS WON
                </span>
              </span>
            </div>
          </div>
          <div className="flex lg:flex-row sm:flex-col gap-2 items-center">
            <span>
              <img
                src="images/counting2.svg"
                alt=""
                className="md:h-16 md:w-16 sm:w-10 sm:h-10"
              />
            </span>
            <span className="flex flex-col  lg:items-start sm:items-center lg:justify-start sm:justify-center ">
              <CountUp
                end={265}
                duration={2}
                enableScrollSpy
                className="lg:text-2xl md:text-lg sm:text-sm"
              />
              <span className="lg:text-2xl md:text-lg sm:text-[8px] text-blue-900 font-semibold">
                STAFF MEMBERS
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountingSection;
