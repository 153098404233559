import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import CookingRange from "./pages/CookingRange";
import ServicesPage from "./pages/ServicesPage";
import PantryAndFastFood from "./pages/PantryAndFastFood";
import Preparation from "./pages/Preparation";
import WashUp from "./pages/WashUp";
import Bakery from "./pages/Bakery";
import ColdEquipmnt from "./pages/ColdEquipmnt";
import HoodExhaustSystem from "./pages/HoodExhaustSystem";
import LgpBanking from "./pages/LgpBanking";
import DisplayCounter from "./pages/DisplayCounter";
import Partners from "./pages/Partners";
import AboutPage from "./pages/AboutPage";
import ContactUs from "./pages/ContactUs";
import CommercialKitchenEquipmentManufacturer from "./pages/CommercialKitchenEquipmentManufacturer";

function App() {
  return (
    <div className=" ">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/partners" element={<Partners />}></Route>
          <Route path="/about-us" element={<AboutPage />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route
            path="/commercial-kitchen-equipment-manufacturer"
            element={<CommercialKitchenEquipmentManufacturer />}
          ></Route>

          <Route path="/cooking-range" element={<CookingRange />}></Route>
          <Route path="/services" element={<ServicesPage />}></Route>
          <Route
            path="/pantry-and-fast-food"
            element={<PantryAndFastFood />}
          ></Route>
          <Route path="/preparation" element={<Preparation />}></Route>
          <Route path="/wash-up" element={<WashUp />}></Route>
          <Route path="/bakery" element={<Bakery />}></Route>
          <Route path="/cold-equipment" element={<ColdEquipmnt />}></Route>
          <Route
            path="/hood-exhaust-system"
            element={<HoodExhaustSystem />}
          ></Route>
          <Route path="/lpg-banking" element={<LgpBanking />}></Route>
          <Route path="/dislay-counter" element={<DisplayCounter />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
