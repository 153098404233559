import React, { useState } from "react";
import emailjs from "@emailjs/browser";

const EnquirySection = ({ showcatalouge }) => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
    requirement: "",
    location: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, phone, requirement } = formData;

    // Check if all required fields are filled
    if (!name || !phone || !requirement) {
      alert("Please fill in all required fields.");
      return;
    }

    emailjs
      .sendForm(
        "service_aubw0qb",
        "template_3kzg8jz",
        e.target,
        "AVANVIxmyNCybjqq-"
      )
      .then(
        () => {
          alert("We are connecting you.");
        },
        (error) => {
          console.log(error.text);
        }
      );

    // Clear the form data after submission
    setFormData({
      name: "",
      phone: "",
      message: "",
      requirement: "",
      location: "",
    });
  };

  return (
    <div className=" h-fit p-4 rounded-lg bg-blue-100 border-[1px] border-blue-900">
      <div className="flex flex-col  gap-8 ">
        <span
          className="flex justify-center snm:text-sm lg:text-xl font-semibold text-blue-950 items-center
            "
        >
          Let us know how we can help
        </span>
        <div className="flex text-blue-800 text-lg flex-col md:gap-2 sm:gap-2 lg:gap-4">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col md:gap-2 sm:gap-2 lg:gap-4 "
          >
            <span className="flex flex-col gap-1">
              <span>Name*</span>
              <input
                name="name"
                value={formData.name}
                onChange={handleChange}
                type="text"
                className="w-full h-[3rem] rounded-lg p-4"
              />
            </span>
            <span className="flex flex-col gap-1">
              <span>Mob No*</span>
              <input
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                type="text"
                className="w-full h-[3rem] rounded-lg p-4"
              />
            </span>
            <span className="flex flex-col gap-1">
              <span>Select Requirement*</span>
              <select
                name="requirement"
                value={formData.requirement}
                onChange={handleChange}
                className="w-full h-[3rem] rounded-lg p-4 "
              >
                <option value=""></option>
                <option value="Display Counter">Display Counter</option>
                <option value="Cooking Range">Cooking Range</option>
                <option value="Service">Service</option>
                <option value="Pantry & Fast Food">Pantry & Fast Food</option>
                <option value="Preparation">Preparation</option>
                <option value="Wash Up">Wash Up</option>
                <option value="Bakery">Bakery</option>
                <option value="Cold Equipment">Cold Equipment</option>
                <option value="Hood Exhaust System">Hood Exhaust System</option>
                <option value="LGP Banking">LGP Banking</option>
              </select>
            </span>
            <span className="flex flex-col gap-1">
              <span>Location (Optional )</span>
              <input
                name="location"
                value={formData.location}
                onChange={handleChange}
                type="text"
                className="w-full h-[3rem] rounded-lg p-4"
              />
            </span>
            <span className="flex flex-col gap-1">
              <span>Messege (Optional )</span>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                type="text"
                className="w-full max-h-[6rem] min-h-[6rem] rounded-lg p-4"
              />
            </span>
            <button
              type="submit"
              className="w-full sm:h-[3rem] lg:h-[4rem] border-[1px] text-white sm:text-lg md:text-sm lg:text-xl font-bold border-gray-300 bg-blue-900 flex justify-center items-center rounded-lg"
            >
              Get Best Price
            </button>
          </form>
          {showcatalouge && (
            <div className="rounded-lg text-white sm:text-lg md:text-sm lg:text-xl text-semibold w-full p-2 h-[3rem] bg-red-700 flex justify-center gap-4 items-center">
              <span>Download Catalouge</span>
              <span>
                <img src="images/topnav2.svg" alt="" className="w-8 h-8" />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EnquirySection;
