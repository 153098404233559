import React, { useState } from "react";
import { RiMenuAddLine } from "react-icons/ri";
import { Link } from "react-router-dom";
const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [menuopen, setMenuopen] = useState(false);
  const togglemenuopen = () => {
    setMenuopen(!menuopen);
  };
  const NavElement = [
    { name: "Home", link: "/" },
    { name: "About Company", link: "/about-us" },
    {
      name: "Our Products",
      dropdownItems: [
        { name: "Display Counter", link: "/dislay-counter" },
        { name: "Cooking Range", link: "/cooking-range" },
        { name: "Service", link: "/services" },
        { name: "Pantry & Fast Food", link: "/pantry-and-fast-food" },
        { name: "Preparation", link: "/preparation" },
        { name: "Wash Up", link: "/wash-up" },
        { name: "Bakery", link: "/bakery" },
        { name: "Cold Equipment", link: "/cold-equipment" },
        { name: "Hood Exhaust System", link: "/hood-exhaust-system" },
        { name: "LGP Banking", link: "/lpg-banking" },
      ],
    },
    { name: "Services", link: "/" },
    { name: "Certifications", link: "/" },
    { name: "Our Partners", link: "/partners" },
    { name: "Contact Us", link: "/contact-us" },
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <header
      className="bg-blue-800  fixed z-50 w-full"
      onMouseLeave={() => {
        setIsDropdownOpen(false);
      }}
    >
      <div className="lg:flex sm:hidden justify-end gap-10 p-4 px-9 relative">
        {NavElement.map((navbar, index) => (
          <div key={index} className="font-semibold text-white">
            {navbar.dropdownItems ? (
              <div className="relative ">
                <button
                  onClick={toggleDropdown}
                  onMouseEnter={() => {
                    setIsDropdownOpen(!isDropdownOpen);
                  }}
                  className="flex flex-row gap-2 items-center "
                >
                  <div>{navbar.name} </div>
                  <span className=" text-sm">{isDropdownOpen ? "▲" : "▼"}</span>
                </button>
                <div className="w-full flex items-center justify-center z-50 relative top-[13.5rem] ">
                  {isDropdownOpen && (
                    <div className="absolute bg-white rounded-lg shadow-md mt-1 p-2 w-[15rem] transition-opacity opacity-100">
                      {navbar.dropdownItems.map((item, i) => (
                        <Link
                          key={i}
                          to={item.link}
                          className="block py-2 px-4 text-gray-800 hover:bg-gray-200"
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <Link to={navbar.link}>{navbar.name}</Link>
            )}
          </div>
        ))}
      </div>
      <div className="sm:flex md:justify-end md:items-end  sm:justify-between sm:items-center w-full md:p-3 sm:p-2 px-9 lg:hidden">
        <div className="md:hidden">
          <img
            src="images/logowhite.png"
            alt=""
            className="h-[2rem] w-[6rem]"
          />
        </div>
        <button
          onClick={togglemenuopen}
          className="border-2 p-1 border-gray-700 rounded-lg bg-red-500"
        >
          <RiMenuAddLine className="sm:text-[1.2rem] md:text-3xl text-white font-semibold" />
        </button>
      </div>
      {menuopen && (
        <div className="sm:flex bg-white flex-col h-[50vh] text-xl overflow-scroll lg:hidden  px-9   relative">
          {NavElement.map((navbar, index) => (
            <div
              key={index}
              className="font-semibold text-black p-4  border-b-2 border-gray-200"
            >
              {navbar.dropdownItems ? (
                <div className="relative">
                  <button
                    onClick={toggleDropdown}
                    onMouseEnter={() => {
                      setIsDropdownOpen(!isDropdownOpen);
                    }}
                    className="flex flex-row gap-2 items-center "
                  >
                    <div>{navbar.name} </div>
                    <span className=" text-sm">
                      {isDropdownOpen ? "▲" : "▼"}
                    </span>
                  </button>
                  <div className="w-full flex items-center justify-center z-50  top-[12rem] ">
                    {isDropdownOpen && (
                      <div className="relative bg-white  rounded-lg shadow-md mt-1 p-2 w-full transition-opacity opacity-100">
                        {navbar.dropdownItems.map((item, i) => (
                          <Link
                            key={i}
                            to={item.link}
                            className="block py-4 px-4 border-b-[0.5px] border-gray-200 text-gray-800 hover:bg-gray-200"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <Link to={navbar.link}>{navbar.name}</Link>
              )}
            </div>
          ))}
        </div>
      )}
    </header>
  );
};

export default Header;
