import React, { useState } from "react";
import {
  FaFacebookSquare,
  FaGoogle,
  FaInstagram,
  FaMinus,
  FaPhoneAlt,
} from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { MdDoubleArrow, MdOutgoingMail } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import { RiContractRightLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Catalouge from "../assets/sahil-traders-digital-catalogue.pdf";

const FooterSection = () => {
  const [showQuickLinks, setShowQuickLinks] = useState(false);
  const [showProductLinks, setShowProductLinks] = useState(false);

  const toggleQuickLinks = () => {
    setShowQuickLinks(!showQuickLinks);
  };
  const toggleProductLinks = () => {
    setShowProductLinks(!showProductLinks);
  };
  const quickLinks = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About Company",
      link: "/about-us",
    },
    {
      name: "Services",
      link: "",
    },
    {
      name: "Certifications",
      link: "",
    },
    {
      name: "Our Partners",
      link: "/partners",
    },
    {
      name: "Contact Us",
      link: "",
    },
    { name: "Bakery", link: "/bakery" },
    { name: "Cold Equipment", link: "/cold-equipment" },
  ];
  const products = [
    { name: "Display Counter", link: "/dislay-counter" },

    { name: "Cooking Range", link: "/cooking-range" },
    { name: "Service", link: "/services" },
    { name: "Pantry & Fast Food", link: "/pantry-and-fast-food" },
    { name: "Preparation", link: "/preparation" },
    { name: "Wash Up", link: "/wash-up" },

    { name: "Hood Exhaust System", link: "/hood-exhaust-system" },
    { name: "LGP Banking", link: "/lpg-banking" },
  ];
  return (
    <div className="bg-blue-900">
      <div className="lg:p-16 sm:p-4 md:p-8 h-full">
        <div className=" flex-col flex items-center justify-center">
          <div className="grid lg:grid-cols-3 sm:grid-cols-1 sm:gap-8 lg:gap-16 ">
            <div className="md:w-[85%] sm:w-full  h-fit rounded-lg shadow-xl bg-white sm:p-4 xl:p-8 flex flex-col gap-4">
              <div>
                <img
                  src="images/logoblue.png"
                  alt=""
                  className="w-[10rem] h-auto"
                />
              </div>
              <div className="flex gap-2 md:text-xl sm:text-sm items-start">
                <span>
                  <FaLocationDot />
                </span>
                <span>
                  Company Address:- Debidanga, Champasari, Siliguri, Landmark -
                  Behind Maa Durga Petrol Pump, West Bengal 734003
                </span>
              </div>
              <div className="flex gap-2 items-center md:text-xl sm:text-sm">
                <span>
                  <MdOutgoingMail />
                </span>
                <Link to="mailto:shahiltraders@gmail.com">
                  shahiltraders@gmail.com
                </Link>
              </div>
              <div className="flex gap-2 items-center md:text-xl sm:text-sm">
                <span>
                  <FaPhoneAlt />
                </span>
                <Link to="tel:9933810052">9933810052</Link>
                <span>/</span>
                <Link to="tel:9933810058">9933810058 </Link>
              </div>
              <div className="flex gap-2 items-center border-t-2 pt-2 text-xl">
                <div className="">Follow Us: </div>
                <div className="flex gap-4 ">
                  <Link to="https://www.instagram.com/shahil_traders_/">
                    <img
                      src="images/instagramlogo.svg"
                      alt=""
                      className="w-6 h-5"
                    />
                  </Link>
                  <Link to="https://goo.gl/maps/6tgbDYLPxJAVriGcA">
                    <img
                      src="images/googlelogo.svg"
                      alt=""
                      className="w-6 h-5"
                    />
                  </Link>
                  <Link to="https://www.facebook.com/people/Shahil-traders/100070165681143/ ">
                    <img
                      src="images/facebooklogo.svg"
                      alt=""
                      className="w-6 h-5"
                    />
                  </Link>
                  <Link
                    className=" "
                    to="https://www.facebook.com/people/Shahil-traders/100070165681143/ "
                  >
                    <img
                      src="images/justdiallogo.png"
                      alt=""
                      className="w-6 h-5"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex sm:flex-col lg:flex-row sm:gap-8 lg:gap-0 lg:justify-between">
              <div className="flex flex-col  gap-4 text-white">
                <div
                  onClick={toggleQuickLinks}
                  className="flex justify-between"
                >
                  <span className="text-3xl font-bold">Quick Links</span>
                  <span className="border-2 border-white h-8 w-8 flex justify-center items-center lg:hidden ">
                    {showQuickLinks ? <FaMinus /> : <FaPlus />}
                  </span>
                </div>
                <div className="lg:flex sm:hidden  flex-col gap-2 sm:text-lg md:text-xl">
                  {quickLinks.map((quicklink, index) => (
                    <Link
                      to={quicklink.link}
                      className="flex flex-row gap-2 items-center"
                      key={index}
                    >
                      <span>
                        <MdDoubleArrow />
                      </span>
                      <span>{quicklink.name}</span>
                    </Link>
                  ))}
                </div>
                {showQuickLinks && (
                  <div className="flex flex-col gap-2 lg:hidden sm:text-lg md:text-xl">
                    {quickLinks.map((quicklink, index) => (
                      <span
                        className="flex flex-row gap-2 items-center"
                        key={index}
                      >
                        <span>
                          <MdDoubleArrow />
                        </span>
                        <span>{quicklink.name}</span>
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-4 text-white">
                <div
                  onClick={toggleProductLinks}
                  className="flex justify-between"
                >
                  <span className="text-3xl font-bold">Our Products</span>
                  <span className="border-2 border-white h-8 w-8 flex justify-center items-center lg:hidden ">
                    {showProductLinks ? <FaMinus /> : <FaPlus />}
                  </span>
                </div>

                <div className="lg:flex sm:hidden flex-col gap-2 sm:text-lg md:text-xl">
                  {products.map((product, index) => (
                    <Link
                      to={product.link}
                      className="flex flex-row gap-2 items-center"
                      key={index}
                    >
                      <span>
                        <MdDoubleArrow />
                      </span>
                      <span>{product.name}</span>
                    </Link>
                  ))}
                </div>
                {showProductLinks && (
                  <div className="flex lg:hidden flex-col gap-2 sm:text-lg md:text-xl">
                    {products.map((product, index) => (
                      <span
                        className="flex flex-row gap-2 items-center"
                        key={index}
                      >
                        <span>
                          <MdDoubleArrow />
                        </span>
                        <span>{product.name}</span>
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <div className="md:w-[50%] lg:w-[75%] sm:w-full md:h-[12rem] lg:h-[10rem]">
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28505.787900357063!2d88.407302!3d26.7372434!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e440e99b6e02d7%3A0x42e90be2d70dc3b1!2sShahil%20Traders!5e0!3m2!1sen!2sin!4v1711091659545!5m2!1sen!2sin"
                  loading="lazy"
                  className="w-full h-full rounded-lg"
                ></iframe>
              </div>
              <Link
                to={Catalouge}
                download="Catalouge"
                target="_blank"
                className="lg:w-[75%] md:w-[50%] sm:w-[75%]  p-4 text-white text-xl font-semibold bg-blue-950 flex items-center justify-between"
              >
                <span>Download Catalouge</span>
                <span>
                  <FaDownload />
                </span>
              </Link>
              <Link
                to="/contact-us"
                className="lg:w-[75%] md:w-[50%] sm:w-[75%]  p-4 text-white text-xl font-semibold bg-blue-950 flex items-center justify-between"
              >
                <span>Enquiry Now</span>
                <span>
                  <RiContractRightLine />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex md:flex-row md:h-fit sm:h-[9rem] sm:flex-col sm:text-xs md:text-lg lg:text-xl sm:justify-start items-center md:justify-between bg-blue-950  sm:p-2 md:p-6 ">
        <div className="  text-white    flex   ">
          Copyrights © Shahil Traders, 2024.
        </div>
        <div className="  text-white      flex gap-2  ">
          <span>Developed By:</span>
          <Link to="https://rebootmarketing.in/">
            Reboot Marketing Pvt. Ltd.
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
