import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import { Link } from "react-router-dom";
import FooterSection from "../component/FooterSection";
import { MetaTags } from "react-meta-tags";

const Partners = () => {
  const clients = [
    { imgsrc: "images/client1.png" },

    { imgsrc: "images/client2.jpg" },
    { imgsrc: "images/client3.png" },
    { imgsrc: "images/client4.png" },
    { imgsrc: "images/client5.png" },
    { imgsrc: "images/client6.png" },
    { imgsrc: "images/client7.png" },
    { imgsrc: "images/client8.jpeg" },
    { imgsrc: "images/client9.png" },

    { imgsrc: "images/client12.png" },
    { imgsrc: "images/client13.png" },
    { imgsrc: "images/client14.png" },
    { imgsrc: "images/client10.jpg" },
    { imgsrc: "images/client11.png" },

    { imgsrc: "images/client14.png" },
    { imgsrc: "images/client1.png" },

    { imgsrc: "images/client2.jpg" },
    { imgsrc: "images/client3.png" },
    { imgsrc: "images/client4.png" },
    { imgsrc: "images/client5.png" },
    { imgsrc: "images/client6.png" },
    { imgsrc: "images/client7.png" },
    { imgsrc: "images/client8.jpeg" },
    { imgsrc: "images/client9.png" },

    { imgsrc: "images/client12.png" },
    { imgsrc: "images/client13.png" },
    { imgsrc: "images/client14.png" },
    { imgsrc: "images/client10.jpg" },
    { imgsrc: "images/client11.png" },

    { imgsrc: "images/client14.png" },
  ];
  return (
    <>
      <MetaTags>
        <title>Shahil Traders Clients </title>
        <meta
          name="description"
          content="Shahil Traders Leading manufacturer Of Commercial Kitchen Equipment in Siliguri North Bengal2024, Pantry, Food Service Equipment, Refrigeration, Bakery Equipment & Display Counter."
        />
      </MetaTags>
      <MainPageTemplate>
        <div className="bg-gray-200 w-full  flex items-center justify-start p-4">
          <span className="flex gap-2 text-lg">
            <Link to="/">Home</Link>
            <span>/</span>
            <span>Our Partners</span>
          </span>
        </div>
        <div className="lg:p-16 md:p-8 sm:p-4">
          <div className="flex flex-col gap-8 justify-center items-center">
            <span className="lg:text-4xl sm:text-lg md:text-2xl font-semibold text-blue-900">
              Over 1000+ top Clients work with us
            </span>
            <div className="lg:w-[80%] sm:w-[90%]">
              <span className="grid lg:grid-cols-5 xxl:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 gap-8 items-center justify-center place-content-center">
                {clients.map((client, index) => (
                  <div
                    className="border-2 sm:p-2 lg:p-4 border-gray-200 flex items-center justify-center xxl:w-[14rem] xxl:h-[14rem] lg:w-[10rem] lg:h-[10rem]"
                    key={index}
                  >
                    <img src={client.imgsrc} alt="" className="" />
                  </div>
                ))}
              </span>
            </div>
          </div>
        </div>
        <FooterSection></FooterSection>
      </MainPageTemplate>
    </>
  );
};

export default Partners;
