import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const ProductLinkSection = () => {
  const productslink = [
    { name: "Display Counter", link: "/dislay-counter" },

    { name: "Cooking Range", link: "/cooking-range" },
    { name: "Service", link: "/services" },
    { name: "Pantry & Fast Food", link: "/pantry-and-fast-food" },
    { name: "Preparation", link: "/preparation" },
    { name: "Wash Up", link: "/wash-up" },
    { name: "Bakery", link: "/bakery" },
    { name: "Cold Equipment", link: "/cold-equipment" },
    { name: "Hood Exhaust System", link: "/hood-exhaust-system" },
    { name: "LGP Banking", link: "/lpg-banking" },
  ];
  return (
    <div className=" h-fit p-4 rounded-lg bg-blue-100 border-[1px] border-blue-900">
      <div className="flex flex-col  gap-8 ">
        <span
          className="flex justify-center text-2xl font-semibold text-blue-950 items-center
            "
        >
          Products
        </span>
        <div className="flex flex-col ">
          {productslink.map((product, index) => (
            <Link
              to={product.link}
              key={index}
              className="flex justify-between border-b-2 border-gray-300 sm:py-2 lg:py-3"
            >
              <span className="lg:text-xl sm:text-lg font-bold text-blue-800">
                {product.name}
              </span>
              <span className="lg:text-xl sm:text-lg  flex justify-center items-center">
                <FaArrowRight />
              </span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductLinkSection;
