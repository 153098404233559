import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const TestimonialSection = () => {
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 725) {
        setSlidesToShow(1);
      } else if (window.innerWidth <= 1024) {
        setSlidesToShow(2);
      } else if (window.innerWidth <= 1780) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(4);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    arrows: false,
    centerMode: false,
  };
  const testimonials = [
    {
      review:
        "Shahil Traders exceeded my expectations with their top-notch Display Counter. The quality and craftsmanship are impressive, making them my go-to for all kitchen equipment needs.",
      name: "Mithun Ghosh",
      city: "Siliguri",
    },
    {
      review:
        "I rely on Shahil Traders for their reliable Cooking Range Service. Their products are durable and efficient, ensuring my kitchen runs smoothly.",
      name: "Amaranth Sarkar",
      city: "Gangtok",
    },
    {
      review:
        "As a bakery owner, I trust Shahil Traders for their Bakery Equipment. Their products are of superior quality, helping me deliver delicious treats to my customers.",
      name: "Premsila Rai",
      city: "Jalpaiguri",
    },
    {
      review:
        "Shahil Traders' Wash Up Equipment is a game-changer for my restaurant. The equipment is easy to use and maintain, saving me time and effort.",
      name: "Mukesh Agarwal",
      city: "Guwahati",
    },
    {
      review:
        "The Cold Equipment from Shahil Traders is a lifesaver for my business. It keeps my ingredients fresh and my customers happy with the quality of my dishes.",
      name: "Aditya Pradhan",
      city: "Siliguri",
    },
    {
      review:
        "Shahil Traders' Hood Exhaust System is a must-have for any commercial kitchen. It keeps the air clean and the environment safe, ensuring a comfortable working space.",
      name: "Kumar Chhetri",
      city: "Jalpaiguri",
    },
  ];
  return (
    <div className=" bg-gray-100 ">
      <div className="lg:p-16 sm:p-4 md:p-8 flex flex-col gap-8">
        <h1 className="w-full flex items-center justify-center font-semibold lg:text-4xl sm:text-lg md:text-2xl text-blue-900">
          Customer Success Stories
        </h1>
        <div className=" w-full flex items-center justify-center  gap-8 ">
          <div className="lg:w-[90%] sm:w-full ml-8 ">
            <Slider {...settings}>
              {testimonials.map((testimonial, index) => (
                <div className="" key={index}>
                  <div className=" sm:w-[95%] sm:h-full lg:h-fit gap-4 p-8 text-center rounded-lg border-[1px] border-gray-200 shadow-lg  items-center justify-center flex flex-col">
                    <div>
                      <img
                        src="images/testimonial1.svg"
                        alt=""
                        className="w-16 h-16"
                      />
                    </div>
                    <div className="text-base">{testimonial.review}</div>
                    <div>
                      <img
                        src="images/testimonial2.svg"
                        alt=""
                        className="w-10 h-10"
                      />
                    </div>
                    <div className="flex gap-4">
                      <div className="text-xl font-bold text-blue-900">
                        {testimonial.name} ,
                      </div>
                      <div className="text-lg">{testimonial.city}</div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
